@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
    --color1: #FFC107;  /* Main Yellow */
    --color2: #FFFFFF;  /* Secondary White */
    --color3: #FF0000;  /* Red */
    --color4: #000000;  /* Black */
    --color5: #00FF00;  /* Green */
    --color-hover: #FFCA28; 
    /* ... other variables */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  vertical-align: middle;
  font-size: 24px;
  margin-right: 8px;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

/* Styles for the rows */
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; /* Space between rows */
}

/* Styles for the HoldingCell */
.HoldingCell {
  background: var(--color2); /* Use your secondary white color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
  padding: 20px; /* Add padding inside the cell */
}

/* Styles for list items and buttons in the sidebar */
.menu li, .settings-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  background-color: var(--color2);
}

/* Styles for vertical alignment of icons in list items */
.menu li span {
  vertical-align: middle;
}

/* Styles for modal overlay */
.uploadModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Styles for modal box */
.uploadModal {
  /* Modal-specific properties */
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  font-family: Arial, sans-serif; /* Or your preferred font */
}

/* Styles for modal header */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Shared styles for search bar and filter dropdown */
.search-bar,
.filter-dropdown {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px; /* Set font size to match */
  margin-bottom: 20px; /* Spacing below the search bar */
}

/* Make the filter dropdown look like the search bar */
.filter-dropdown {
  appearance: none; /* Remove default system appearance */
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: var(--color2);
  cursor: pointer;
}

/* Align search bar and filter dropdown in the same row with flexbox */
.content-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust space between elements */
}

/* Allow search bar to grow and take available space */
.search-bar {
  flex-grow: 1; /* Take available space */
  width: auto; /* Override any width set previously */
}

/* Set a max-width for the filter dropdown to prevent it from stretching */
.filter-dropdown {
  max-width: 200px; /* Adjust as necessary */
  flex-shrink: 0; /* Prevent filter dropdown from shrinking */
}

/* Styles for modal header */
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Styles for modal body */
.modalBody {
  display: flex;
  flex-direction: column;
  background-color: var(--color2);
}

/* Styles for file input in modal body */
.modalBody input[type="file"] {
  display: none; /* Hide the default input */
}

/* Styles for error messages */
.error {
  color: var(--color2);
  margin-bottom: 10px;
}

/* Styles for modal buttons */
.modalBody button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  background-color: var(--color2);
  color: var(--color2);
}

/* Styles for 'X' button in modal header */
.modalHeader button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Drag and Drop Area */
.dropArea {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.dragOver {
  border-color: var(--color3);
}

/* Progress Bar */
.progressBarContainer {
  width: 100%;
  background-color: var(--color4);
  border-radius: 5px;
}

.progressBar {
  height: 20px;
  background-color: var(--color3);
  border-radius: 5px;
  width: 0%; /* Initial width 0%, will be set by inline style */
}

/* Styles for the main dashboard layout */
.dashboard {
  display: flex;
  justify-content: space-between;
}

/* Styles for the sidebar */
.sidebar {
  position: sticky;
  top: 0; /* Stick to the top */
  min-width: 265px;
  width: 100%;
  background-color: var(--color2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 300px;
}

/* Styles for user profile section in the sidebar */
.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Styles for sidebar menu */
.menu ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

/* Styles for the main content area */
.content {
  flex-grow: 1;
 /* padding: 20px; */
  overflow-y: auto;
  padding-top: 70px;  
 /* margin-left: 300px;  Adjust this value based on the width of your sidebar */
}


/* Styles for the content header */
.content-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Styles for category cards */
.category-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.uploaded-files-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.uploaded-files-title {
  font-size: 2rem;
  color: var(--color4);
  margin: 0; /* Remove any default margin */
  margin-bottom: 20px;
  display: flex;
  align-items: center; /* Center align inside the title */
  font-weight: bold;
}

/* Styles for the cog button to align it with the far right */
.uploaded-files-header button {
  background: none;
  border: none;
  cursor: pointer;
  align-items: center;
}

/* Styles for individual file items */
.file-item {
  background-color: var(--color2);
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Spacing inside */
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Subtle shadow for a floating effect */
  margin-top: 10px; /* Margin from the title */
}

.file-item:hover {
  background-color: var(--color-hover);
}

.file-item:last-child {
  border-bottom: none;
}

.file-item:hover {
  background-color: var(--color-hover);
}

.logo-section {
  display: flex; /* Aligns items horizontally */
  justify-content: center; /* Center horizontally */
  text-align: center; /* Center text */
  align-items: center; /* Centers items vertically */
  width: 100%; /* Full width of the sidebar */
  padding: 10px; /* Adjust as needed */
}

.logo-section img {
  height: 1.8em; /* Sets the height of the logo to match the text size */
  display: block; /* Make text a block element */
  margin-right: 10px; /* Space between logo and text */
}

.logo-text {
  display: block; /* Make text a block element */
  font-size: 1.8em; /* Adjust the font size as needed */
}

.user-settings {
  margin-top: auto; /* Pushes it to the bottom of the sidebar */
  width: 100%;
}

.settings-button {
  background-color: var(--color1);
  color: var(--color-2);
  border: none; /* Removes the border */
  padding: 10px 15px; /* Adds padding inside the button */
  margin: 5px 0; /* Adds margin between buttons */
  border-radius: 5px; /* Slightly rounded corners for the button */
  display: flex; /* Uses flexbox for internal alignment */
  align-items: center; /* Centers items vertically */
  justify-content: start; /* Aligns content to the left */
  width: 100%; /* Ensures the button stretches the full width of the sidebar */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Adds a slight shadow for depth */
  cursor: pointer; /* Changes the mouse cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.settings-button:hover {
  background-color: var(--color2);
}

.settings-button .material-icons {
  margin-right: 10px; /* Adds spacing between the icon and the text */
}


.user-info-and-settings {
  margin-top: auto; /* This will push it to the bottom */
  width: 100%;
}

/* Ensures buttons and list items span the full width */
.menu button,
.menu li {
  width: 100%; /* Full width */
}

/*File deletion CSS */

.file-item button {
  /* Styling for the delete button */
  margin-left: 10px;
  background-color: var(--color1);
  color: var(--color-2);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.file-item button:hover {
  /* Slightly darker red on hover */
  background-color: var(--color3);;
}

/* Custom styles for buttons to remove default browser styles */
/* Note: Specific button styles should be defined here. */
/* If you don't want any general button styling, you can remove this section. */

@media (max-width: 768px) {
  .sidebar,
  .right-sidebar {
    width: 100%;
  }
  .content,
  .right-sidebar {
    padding: 10px;
  }
  .category-cards {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.chat-interface {
}

.chat-interface input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
}

.chat-interface button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
  width: 25%;
  margin-right: 10px;
}

.chat-interface button:hover {
  background-color: var(--color-hover);
}

.chat-response {
  margin-top: 15px;
  background-color: var(--color-1);
  padding: 10px;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
}

.chat-history {
  max-height: 300px;
  overflow-y: auto;
}

.message {
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.message.user {
  text-align: right;
}

.message.bot {
  text-align: left;
}

.message-timestamp {
  font-size: 0.8em;
  opacity: 0.6;
}

.section-container {
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Spacing inside the bubble */
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Shadow for a floating effect */
  max-width: 100%; /* Maximum width of the chat container */
  margin: auto; /* Center align the container */
  margin-top: 20px;  /* Space at the top */
  margin-bottom: 20px; /* Space at the bottom */
}

.message-group {
  margin-bottom: 15px; /* Space between message groups */
}

.message {
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
}

.question {
  background-color: #e7e7e7; /* Different color for question */
}

.answer {
  background-color: #d1f0d1; /* Different color for answer */
}

.input-group {
  display: flex; /* Aligns children (input and button) in a row */
  align-items: center; /* Aligns children vertically in the center */
  margin-bottom: 20px; /* Space at the bottom */
}

.input-group input {
  flex-grow: 1; /* Allows the input to fill available horizontal space */
  margin-right: 10px; /* Adds some space between the input and the button */
}

.burger-icon {
  display: none; /* Hidden by default */
  cursor: pointer;
  /* additional styling here (size, color, etc.) */
}

/* Styles for the top bar */
.top-bar {
  display: none; /* Hidden by default */
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-1);
  padding: 10px 20px; /* Padding on the sides */
  color: var(--color-5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2001;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Logo Section */
.logo-section img {
  height: 25px; /* Smaller logo */
}

@media (max-width: 768px) {
  .top-bar, .burger-icon {
    display: flex; /* Show on smaller screens */
  }

  .burger-icon {
    position: fixed; /* or absolute, based on your layout */
    top: 10px; /* adjust as needed */
    left: 10px; /* adjust as needed */
    z-index: 2000; /* higher than other elements */
  }

  .sidebar {
    position: fixed;
    left: 0;
    width: 80%; /* Adjust the width of the sidebar */
    max-width: 300px; /* Adjust maximum width as needed */
    transform: translateX(-100%); /* Start off-screen */
    transition: transform 0.3s ease-in-out;
    z-index: 1000; /* Ensure it's above other content but below the burger icon */
    height: 100%; /* Full height */
  }

  .sidebar.open {
    transform: translateX(0); /* Slide in */
  }

  .content {
    padding: 10px;
    margin-left: 0; /* Reset margin when sidebar is collapsed */
  }

  .menu li, .settings-button {
    flex-direction: column;
    align-items: flex-start;
  }

  .modalBody, .section-container {
    padding: 10px;
  }

  .input-group button, .file-item button {
    padding: 12px 20px;
    font-size: 16px;
  }

  .search-bar, .filter-dropdown {
    width: 100%;
  }

  .chat-interface input[type="text"], .chat-interface button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Other Adjustments */
.file-item {
  word-break: break-word;
}

.error {
  font-size: 16px;
}

.category-title {
  font-size: 1.5rem; /* Smaller than the main title */
  color: var(--color4);
  margin-bottom: 10px; /* Space below the title */
  margin-top: 10px;
  /* Add any other styles that the "Categorized Files" title has */
}

.file-item-container {
  margin-bottom: 20px; /* Adjust as needed */
}

.file-transcript {
  margin-top: 10px; /* Adjust as needed */
  background-color: var(--color-2);
  padding: 10px; /* Example padding */
  border-radius: 5px; /* Example border-radius */
}

/* Add responsive design adjustments if necessary */
@media (max-width: 768px) {
  .card-row {
    flex-direction: column;
  }

  .card.large, .card.small {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}


/* Additional Responsive Adjustments as Needed */
